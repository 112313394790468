import React from 'react';

const DropdownIndicator = () => (
  <svg
    width='13'
    height='8'
    viewBox='0 0 13 8'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
      d='M7.15364 7.70893L12.7221 1.72753C13.3131 1.09329 12.8987 8.85468e-09 12.0685 8.14384e-08L6.5 5.68248e-07L0.931546 1.05506e-06C0.101284 1.12764e-06 -0.313151 1.0933 0.277906 1.72753L5.84497 7.70893C6.20795 8.09702 6.79066 8.09702 7.15364 7.70893Z'
      fill='#BBBBBB'
    />
  </svg>
);

export default DropdownIndicator;
