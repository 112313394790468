import React, { useState } from 'react';
import get from 'lodash-es/get';
import isNumber from 'lodash-es/isNumber';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import { ifProp, prop } from 'styled-tools';
import { Spinner } from 'reactstrap';

import { getPriceChange, formatTime } from '../../../utils/functions';
import OptionsTitle from './OptionsTitle';
import BlockStyles from 'components/BlockStyles';
import SpinnerContainer from '../../SpinnerContainer';
import dayjs from '../../../utils/dayjs';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 0.875rem;
  line-height: 24px;

  ${ifProp(
    { $mode: 'historical' },
    css`
      align-items: flex-start;
      overflow-x: auto;
    `,
    css`
      align-items: center;

      @media (max-width: ${prop(
          'theme.breakpoints.xl'
        )}) and (min-width: ${prop('theme.breakpoints.lg')}) {
        align-items: flex-start;
        overflow-x: auto;
      }
    `
  )}
`;

const TableWrapper = styled.div`
  ${ifProp(
    { $mode: 'historical' },
    css`
      width: fit-content;
    `,
    css`
      width: 100%;

      @media (max-width: ${prop(
          'theme.breakpoints.xl'
        )}) and (min-width: ${prop('theme.breakpoints.lg')}) {
        width: fit-content;
      }
    `
  )}
`;

const Caption = styled.caption`
  caption-side: top;
  height: 32px;
  color: ${prop('theme.colors.black')};
  text-align: center;
  border: 1px solid ${prop('theme.colors.borderGrey')};
  border-radius: 0.5rem 0.5rem 0 0;
  background-color: ${prop('theme.colors.backgroundGreyLighter')};
  padding: 0.75rem 1.25rem;
  line-height: 0.5rem;

  .month {
    text-align: left;
    font-weight: ${prop('theme.fontWeights.medium')};
    width: 100%;
    margin-bottom: -0.5rem;
  }

  .symbol {
    font-weight: ${prop('theme.fontWeights.bold')};
    width: 100%;
  }

  .date {
    font-weight: ${prop('theme.fontWeights.medium')};
    width: 100%;
    margin-top: -0.5rem;
    text-align: right;
  }
`;

const LoadingContainer = styled.div`
  border: 1px solid ${prop('theme.colors.borderGrey')};
  border-radius: 0.5rem;
`;

const StyledTable = styled.table`
  margin: 0;
  width: 100%;
  border-radius: 0 0 8px 8px;
  border-collapse: separate;
  border-spacing: 0;

  @media (max-width: ${prop('theme.breakpoints.sm')}) {
    font-size: 0.6875rem;
  }
`;

const TableRow = styled.tr`
  td {
    border-left: 1px solid ${prop('theme.colors.borderGrey')};
    text-align: right;
    padding: 0.25rem;

    &:nth-child(${(props) => props.middleColumn}) {
      text-align: center;
      font-weight: ${prop('theme.fontWeights.bold')};
      background-color: ${prop('theme.colors.backgroundGreyLightest')};
    }

    &:last-child {
      border-right: 1px solid ${prop('theme.colors.borderGrey')};
    }

    @media (min-width: ${prop('theme.breakpoints.md')}) {
      min-width: 36px;
      padding: 0.375rem 1rem;
    }
  }

  &:last-child {
    td {
      border-bottom: 1px solid ${prop('theme.colors.borderGrey')};

      &:first-child {
        border-bottom-left-radius: 8px;
      }

      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }

  &:hover {
    td {
      position: relative;
      padding-top: 0.313rem;
      padding-bottom: 0.313rem;
      background-color: ${prop('theme.colors.yellowHighlight')};
      border-top: 1px solid ${prop('theme.colors.orangeBorderHighlight')};
      border-bottom: 1px solid ${prop('theme.colors.orangeBorderHighlight')};

      &:first-child {
        border-left: 1px solid ${prop('theme.colors.orangeBorderHighlight')};
      }

      &:last-child {
        border-right: 1px solid ${prop('theme.colors.orangeBorderHighlight')};
      }
    }
  }
`;

const TableHead = styled.thead`
  background-color: ${prop('theme.colors.backgroundGreyLightest')};

  .strike {
    text-align: center;
    font-weight: ${prop('theme.fontWeights.bold')};
  }
`;

const StyledTh = styled.th`
  text-transform: uppercase;
  text-align: right;
  font-weight: ${prop('theme.fontWeights.medium')};
  border-left: 1px solid ${prop('theme.colors.borderGrey')};
  border-bottom: 1px solid ${prop('theme.colors.borderGrey')};
  padding: 0 0.25rem;

  &:last-child {
    border-right: 1px solid ${prop('theme.colors.borderGrey')};
  }

  @media (min-width: ${prop('theme.breakpoints.md')}) {
    ${ifProp(
      { $mode: 'historical' },
      css`
        padding: 0 0.5rem;
      `,
      css`
        padding: 0 1rem;

        @media (max-width: ${prop(
            'theme.breakpoints.xl'
          )}) and (min-width: ${prop('theme.breakpoints.lg')}) {
          padding: 0 1.5rem;
        }
      `
    )}
  }
`;

const Message = styled.div`
  padding: 17px 0;
  border: 1px solid ${prop('theme.colors.borderGrey')};
  border-top: none;
  border-radius: 0 0 8px 8px;
  text-align: center;
  width: 100%;
`;

const propTypes = {
  title: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fields: PropTypes.array,
  date: PropTypes.string,
  template: PropTypes.string,
  tooltipTitle: PropTypes.string,
  tooltip: PropTypes.object,
  data: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  optionSymbols: PropTypes.array,
  loading: PropTypes.bool,
  month: PropTypes.string,
  symbol: PropTypes.string,
  mode: PropTypes.string
};

const defaultProps = {
  title: [],
  description: [],
  fields: [],
  date: '',
  template: '',
  tooltipTitle: '',
  tooltip: {},
  data: {},
  optionSymbols: [],
  loading: true,
  month: '',
  symbol: '',
  mode: ''
};

function OptionChain({
  title,
  description,
  fields,
  date,
  template,
  tooltipTitle,
  tooltip,
  data,
  optionSymbols,
  loading,
  month,
  symbol,
  mode
}) {
  const [numberOfStrikes, setNumberOfStrikes] = useState({
    value: 12,
    label: '12 STRIKES'
  });

  function getFields(optionSymbol) {
    const optionData = (data && data[optionSymbol]) || {};

    const summary = optionData.Summary || {};
    const trade = optionData.Trade || {};
    const quote = optionData.Quote || {};
    const prevDayClosePrice = !isNaN(get(summary, 'prevDayClosePrice', 'N/A'))
      ? get(summary, 'prevDayClosePrice', 'N/A')
      : 'N/A';
    const currentPrice = !isNaN(get(trade, 'price', 'N/A'))
      ? get(trade, 'price', 'N/A')
      : 'N/A';

    const dataLookup = {
      summary: summary,
      trade: trade,
      quote: quote
    };

    const getSanitizedValue = (field) => {
      let value;
      const fieldDefault = field.default_value || 'N/A';
      const decimalPoints = field.decimal_points || 2;
      if (field.name.toLowerCase() === 'net ch.') {
        value =
          field.data_type && field.data_type.toLowerCase() === 'change'
            ? getPriceChange(currentPrice, prevDayClosePrice)
            : getPriceChange(optionData.open, optionData.close);
      } else if (field.data_type && field.data_type.length > 0) {
        value = get(
          dataLookup[field.data_type],
          field.dxfeed_value,
          fieldDefault
        );
      } else {
        value = get(optionData, field.dxfeed_value, fieldDefault);
      }

      return isNumber(value) ? value.toFixed(decimalPoints) : fieldDefault;
    };

    return fields.map(getSanitizedValue);
  }

  const determineLastUpdatedDate = () => {
    const latestTimestamp = Math.max(
      ...Object.keys(data).map((value) => {
        const trade = data[value].Trade;
        const summary = data[value].Summary;

        if (trade != null && trade.time) {
          return trade.time;
        }

        if (summary && summary.prevDayClosePrice !== 'N/A') {
          const today = dayjs().tz();
          // If before trading day has started.
          if (today.hour() < 7) {
            today.date(today.date() - 1) && today.hour(14);
          } else {
            today.hour(7);
          }
          return today;
        }

        return 0;
      })
    );

    return isNumber(latestTimestamp) && latestTimestamp > 0
      ? formatTime(latestTimestamp)
      : 'N/A';
  };

  const callFieldNames = fields.map((field) => field.name);
  const putFieldNames = callFieldNames.slice().reverse();
  const askIndex = putFieldNames.findIndex(
    (field) => field.toLowerCase() === 'ask'
  );
  const bidIndex = putFieldNames.findIndex(
    (field) => field.toLowerCase() === 'bid'
  );
  // rearrange bid + ask in put fields, if they exist
  if (askIndex > -1 && bidIndex > -1) {
    putFieldNames.splice(bidIndex, 0, putFieldNames.splice(askIndex, 1));
  }

  const headerFields = [...callFieldNames, 'strike', ...putFieldNames];
  const tableHeaders = headerFields.map((headerField, index) => (
    <StyledTh
      $mode={mode}
      key={`${headerField}_${index}`}
      className={headerField === 'strike' && headerField}
    >
      {headerField}
    </StyledTh>
  ));

  const combinedOptionSymbols = {};

  optionSymbols.forEach((symbol) => {
    const sanitizedSymbol = symbol.split('/')[1].split(':')[0];
    // if we have a "special expiration", then we have an extra character in our typeically 4-character option root
    // i.e. instead of S01M, we could have something like SW01M
    const specialExpiration = sanitizedSymbol.charAt(4).match(/[A-Z]/i);
    const typeIndex = specialExpiration ? 16 : 15;
    const type = sanitizedSymbol.charAt(typeIndex);
    const strike = sanitizedSymbol.substr(
      typeIndex + 1,
      sanitizedSymbol.length
    );
    if (!combinedOptionSymbols[strike]) {
      combinedOptionSymbols[strike] = {};
    }
    if (type === 'C') {
      combinedOptionSymbols[strike].callSymbol = symbol;
    } else {
      combinedOptionSymbols[strike].putSymbol = symbol;
    }
  });

  const totalStrikes = Object.keys(combinedOptionSymbols);
  const startOfMiddle = Math.floor(
    (totalStrikes.length - numberOfStrikes.value) / 2
  );
  const startingIndex = startOfMiddle > 0 ? startOfMiddle : 0;
  const optionRows = totalStrikes
    .sort()
    .slice(startingIndex, startingIndex + numberOfStrikes.value)
    .map((strike) => {
      const { callSymbol, putSymbol } = combinedOptionSymbols[strike];
      const callFields = getFields(callSymbol);
      const putFields = getFields(putSymbol).reverse();
      // rearrange bid + ask in put fields, if they exist
      if (askIndex > -1 && bidIndex > -1) {
        putFields.splice(bidIndex, 0, putFields.splice(askIndex, 1));
      }

      const rowFields = [...callFields, strike, ...putFields];
      const middleIndex = Math.floor(rowFields.length / 2) + 1;

      return (
        <TableRow key={strike} middleColumn={middleIndex}>
          {rowFields.map((field, index) => (
            <td key={`${strike}_${index}`}>{field}</td>
          ))}
        </TableRow>
      );
    });

  const symbolPrefix = symbol.substr(1).split(':')[0];
  const monthCode = symbolPrefix.substr(symbolPrefix.length - 3);
  const symbolRoot = symbolPrefix.substr(0, symbolPrefix.length - 3);
  const tableCaption = `${symbolRoot} ${monthCode}`;
  return (
    <BlockStyles template={template}>
      <div className='block'>
        <OptionsTitle
          title={title}
          description={description}
          tooltipTitle={tooltipTitle}
          tooltip={tooltip}
          date={date}
          numberOfStrikes={numberOfStrikes}
          handleStrikeChange={(option) =>
            setNumberOfStrikes({ value: option.value, label: option.label })
          }
          lastUpdated={determineLastUpdatedDate()}
          mode={mode}
        />
        {loading ? (
          <LoadingContainer>
            <SpinnerContainer>
              <Spinner size='lg' />
            </SpinnerContainer>
          </LoadingContainer>
        ) : (
          <Container $mode={mode}>
            <TableWrapper $mode={mode}>
              <StyledTable>
                <Caption>
                  <div className='month'>{`${month} 20${monthCode.substr(
                    1
                  )}`}</div>
                  <div className='symbol'>{tableCaption}</div>
                  {date && <div className='date'>{date}</div>}
                </Caption>
                <TableHead>
                  <tr>{tableHeaders}</tr>
                </TableHead>
                <tbody>{optionSymbols.length > 0 && optionRows}</tbody>
              </StyledTable>
              {optionSymbols.length === 0 && (
                <Message>No options data for selected product</Message>
              )}
            </TableWrapper>
          </Container>
        )}
      </div>
    </BlockStyles>
  );
}

OptionChain.propTypes = propTypes;
OptionChain.defaultProps = defaultProps;

export default OptionChain;
