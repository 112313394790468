import React, { useContext } from 'react';
import PropTypes from 'prop-types';

import MonthContext from '../../../context/MonthContext';
import MarketDataContext from '../../../context/MarketDataContext';
import OptionChain from './OptionChain';

const propTypes = {
  symbol: PropTypes.string,
  template: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  fields: PropTypes.array
};

const defaultProps = {
  symbol: '',
  template: '',
  title: [],
  fields: []
};

function OptionChainBlock({ symbol, template, title, fields }) {
  const { selectedMonth } = useContext(MonthContext);
  const { summaryTradeData, isSummaryTradeLoading } = useContext(
    MarketDataContext
  );

  const optionSymbols = selectedMonth ? selectedMonth.optionSymbols : [];

  return (
    <OptionChain
      symbol={symbol}
      template={template}
      title={title}
      fields={fields}
      data={summaryTradeData}
      month={selectedMonth.month}
      symbol={selectedMonth.symbol}
      optionSymbols={optionSymbols}
      loading={isSummaryTradeLoading}
    />
  );
}

OptionChainBlock.propTypes = propTypes;
OptionChainBlock.defaultProps = defaultProps;

export default OptionChainBlock;
