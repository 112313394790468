import React, { useState } from 'react';
import styled from 'styled-components';
import { prop } from 'styled-tools';
import Select, { components } from 'react-select';
import PropTypes from 'prop-types';
import { PrismicRichText } from '@prismicio/react';

import Tooltip from 'components/Tooltip';
import DropdownIndicatorIcon from 'components/DropdownIndicatorIcon';
import theme from 'styles/theme';

const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 1.75rem 0 0.5rem;
  position: relative;

  a {
    margin: 0;
  }

  .strike-select {
    width: 7rem;
    font-size: 0.875rem;
    text-transform: uppercase;
    align-self: center;

    @media (max-width: ${prop('theme.breakpoints.lg')}) {
      align-self: flex-end;
    }

    &:hover {
      div div div svg path {
        fill: ${prop('theme.colors.greyDark')};
      }
    }
  }

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex-direction: column;
  }
`;

const ButtonContainer = styled.div`
  display: flex;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    margin-top: 0.2rem;
    position: absolute;
    right: 0;
  }
`;

const TitleContainer = styled.div`
  width: fit-content;
  flex: 1 1 70%;

  @media (max-width: ${prop('theme.breakpoints.lg')}) {
    flex: 1 1 auto;
    align-self: flex-start;
  }

  p {
    font-size: 0.875rem;
  }

  div {
    &:last-child {
      font-size: 0.875rem;
    }
  }
`;

const Title = styled.h2`
  font-size: 1.125rem;
  line-height: 32px;
  font-weight: ${prop('theme.fontWeights.semiBold')};
  color: ${prop('theme.colors.black')};
  margin: 0;
`;

const TooltipContainer = styled.div`
  margin-left: 0.75rem;
`;

const DropdownIndicator = (props) => (
  <components.DropdownIndicator {...props}>
    <DropdownIndicatorIcon />
  </components.DropdownIndicator>
);

const propTypes = {
  title: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
  tooltipTitle: PropTypes.string,
  tooltip: PropTypes.object,
  date: PropTypes.string,
  numberOfStrikes: PropTypes.object,
  handleStrikeChange: PropTypes.func,
  lastUpdated: PropTypes.string
};

const defaultProps = {
  title: [],
  description: [],
  tooltipTitle: '',
  tooltip: {},
  date: '',
  numberOfStrikes: {},
  handleStrikeChange: () => {},
  lastUpdated: ''
};

function OptionsTitle({
  title,
  description,
  tooltipTitle,
  tooltip,
  date,
  numberOfStrikes,
  handleStrikeChange,
  lastUpdated
}) {
  const tableTitle =
    (title && title.text) || (title && title[0] && title[0].text) || '';

  const strikeOptions = [
    { label: '12 STRIKES', value: 12 },
    { label: '24 STRIKES', value: 24 },
    { label: 'ALL STRIKES', value: 100 }
  ];

  return (
    <TitleWrapper>
      <TitleContainer>
        <Title>{tableTitle}</Title>
        {description.text ? (
          <PrismicRichText field={description.richText} />
        ) : (
          lastUpdated && <div>Data last updated at {lastUpdated}</div>
        )}
      </TitleContainer>
      <ButtonContainer>
        {tooltip && (
          <TooltipContainer>
            <Tooltip
              title={tooltipTitle}
              content={tooltip}
              place='bottom'
              triggerValue={tableTitle}
            />
          </TooltipContainer>
        )}
      </ButtonContainer>
      <Select
        className='strike-select'
        defaultValue={{ label: '12 STRIKES', value: 12 }}
        onChange={handleStrikeChange}
        options={strikeOptions}
        value={numberOfStrikes}
        components={{ DropdownIndicator, IndicatorSeparator: () => null }}
        styles={{
          control: (provided) => ({
            ...provided,
            boxShadow: 'none',
            border: 'none'
          }),
          valueContainer: (provided) => ({
            ...provided,
            display: 'flex',
            justifyContent: 'flex-end',
            paddingRight: '0'
          }),
          dropdownIndicator: (provided, state) => {
            return {
              ...provided,
              padding: '0.25rem',
              svg: {
                path: {
                  fill: state.selectProps.menuIsOpen
                    ? theme.colors.greyDark
                    : provided.fill
                }
              }
            };
          }
        }}
      />
    </TitleWrapper>
  );
}

OptionsTitle.propTypes = propTypes;
OptionsTitle.defaultProps = defaultProps;

export default OptionsTitle;
